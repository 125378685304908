<br>
<!-- Render the prompt Dialog for task -->
<ejs-dialog #popPromptDialogTask 
    [buttons]='promptDlgButtonsTask' 
    [visible]='hidden' 
    [header]='promptHeaderTask' 
    [animationSettings]='animationSettings' 
    [showCloseIcon]='showCloseIcon'
    [enableResize]='true'
    [width]='promptWidth'
    [minHeight]='promptHeight'>
    <!-- Prompt Dialog content  -->
    <ng-template #content>
        <table style="border-collapse: separate;border-spacing: 10px;width:330px;height:150px;margin: 0px -5px 0px;">
            <tr>
                <td>Summary:</td>
            </tr>
            <tr>
                <td><span class="e-input-group">
                <textarea type="text" #taskDescription id="taskDescription" (focus)='onFocus()' (blur)='onBlur()' name="taskDescription" class="e-input" style="width:300px; height:150px;"></textarea>
                </span></td>
            </tr>
        </table>
    </ng-template>
</ejs-dialog>

<!-- Render the prompt Dialog for SMS -->
<ejs-dialog #popPromptDialogSMS 
    [buttons]='promptDlgButtonsSMS' 
    [visible]='hidden' 
    [header]='promptHeaderSMS' 
    [animationSettings]='animationSettings' 
    [showCloseIcon]='showCloseIcon'
    [enableResize]='true'
    [width]='promptWidth'
    [minHeight]='promptHeight'>
    <!-- Prompt Dialog content  -->
    <ng-template #content>
        <table style="border-collapse: separate;border-spacing: 10px;width:330px;height:150px;margin: 0px -5px 0px;">
            <tr>
                <td>Summary:</td>
            </tr>
            <tr>
                <td><span class="e-input-group">
                <textarea type="text" #smsContent id="SMSContent" (focus)='onFocus()' (blur)='onBlur()' name="SMSContent" class="e-input" style="width:300px; height:150px;"></textarea>
                </span></td>
            </tr>
        </table>
    </ng-template>
</ejs-dialog>

<!-- Render the prompt Dialog for email -->
<ejs-dialog #popPromptDialogEmail 
    [buttons]='promptDlgButtonsEmail' 
    [visible]='hidden' 
    [header]='promptHeaderEmail' 
    [animationSettings]='animationSettings' 
    [showCloseIcon]='showCloseIcon'
    [enableResize]='true'
    [width]='promptWidth'
    [minHeight]='promptHeight'>
    <!-- Prompt Dialog content  -->
    <ng-template #content>
        <table style="border-collapse: separate;border-spacing: 10px;width:330px;height:150px;margin: 0px -5px 0px;">
            <tr>
                <td>Summary:</td>
            </tr>
            <tr>
                <td><span class="e-input-group">
                <textarea type="text" #emailContent id="EmailContent" (focus)='onFocus()' (blur)='onBlur()' name="EmailContent" class="e-input" style="width:300px; height:150px;"></textarea>
                </span></td>
            </tr>
        </table>
    </ng-template>
</ejs-dialog>

<!-- Render the prompt Dialog for meeting -->
<ejs-dialog #popPromptDialogMeet 
    [buttons]='promptDlgButtonsMeet' 
    [visible]='hidden' 
    [header]='promptHeaderMeet' 
    [animationSettings]='animationSettings' 
    [showCloseIcon]='showCloseIcon'
    [enableResize]='true'
    [width]='promptWidth'
    [minHeight]='promptHeight'>
    <!-- Prompt Dialog content  -->
    <ng-template #content>
        <table style="border-collapse: separate;border-spacing: 10px;width:330px;height:150px;margin: 0px -5px 0px;">
            <tr>
                <td>Summary:</td>
            </tr>
            <tr>
                <td><span class="e-input-group">
                <textarea type="text" #meetDescription id="meetDescription" (focus)='onFocus()' (blur)='onBlur()' name="meetDescription" class="e-input" style="width:300px; height:150px;"></textarea>
                </span></td>
            </tr>
        </table>
    </ng-template>
</ejs-dialog>

<!-- Render the prompt Dialog for a Deal -->
<ejs-dialog #popPromptDialogDeal
    [buttons]='promptDlgButtonsDeal' 
    [visible]='hidden' 
    [header]='promptHeaderDeal' 
    [animationSettings]='animationSettings' 
    [showCloseIcon]='showCloseIcon'
    [enableResize]='true'
    [width]='promptWidth'
    [minHeight]='promptHeight'>
    <!-- Prompt Dialog content  -->
    <ng-template #content>
        <table style="border-collapse: separate;border-spacing: 10px;width:330px;height:150px;margin: 0px -5px 0px;">
            <tr>
                <td>Summary:</td>
            </tr>
            <tr>
                <td><span class="e-input-group">
                <textarea type="text" #dealContent id="DealContent" (focus)='onFocus()' (blur)='onBlur()' name="DealContent" class="e-input" style="width:300px; height:150px;"></textarea>
                </span></td>
            </tr>
        </table>
    </ng-template>
</ejs-dialog>

<ejs-toast #element (created)="onCreateToast($event)" [position] = 'position' ></ejs-toast>
<ejs-grid #grid [dataSource]='dataContacts' 
                allowSorting='true' 
                allowPaging='false' 
                [pageSettings]='this.pageSettings' 
                [toolbar]='this.toolbar' 
                [sortSettings]='this.initialSort' 
                [editSettings]='this.editSettings' 
                (actionComplete)='OnDataChange($event)' 
                allowReordering='true' 
                showColumnChooser='true' 
                (queryCellInfo)="queryCellInfo($event)"
                [allowResizing]='true'>
    <e-columns>
            <e-column field='btn_action_meet' headerText='Meet' [allowFiltering]='false' [allowSorting]="false" [allowSearching]="false" [allowEditing]="false" [showInColumnChooser]='false' width='50'>
                <ng-template #template let-dataContacts> 
                   <input type="image" src="assets/icons/calendar-icon.png" (click)="enque($event, dataContacts, 'meet')" style="width: 22px;" />
                </ng-template>
            </e-column>
           <e-column field='btn_action_email' headerText='Email' [allowFiltering]='false' [allowSorting]="false" [allowSearching]="false" [showInColumnChooser]='false' [allowEditing]="false" width='50'>
                <ng-template #template let-dataContacts> 
                    <input type="image" src="assets/icons/email-icon.png" (click)="enque($event, dataContacts, 'email')"  style="width:22px;" />
                </ng-template>
            </e-column>
            <e-column field='btn_action_sms' headerText='SMS' [allowFiltering]='false' [allowSorting]="false"  [allowSearching]="false" [showInColumnChooser]='false' [allowEditing]="false" width='50'>
                <ng-template #template let-dataContacts> 
                    <input type="image" src="assets/icons/sms-icon.png" (click)="enque($event, dataContacts, 'sms')"  style="width:22px;" />
                </ng-template>
            </e-column>
            <e-column field='btn_action_task' headerText='Task' [allowFiltering]='false' [allowSorting]="false" [allowSearching]="false" [showInColumnChooser]='false' [allowEditing]="false" width='50'>
                <ng-template #template let-dataContacts> 
                    <input type="image" src="assets/icons/task-icon.png" (click)="enque($event, dataContacts, 'task')"  style="width:22px;" />
                </ng-template> 
            </e-column>
            <e-column field='btn_action_deal' headerText='Deal' [allowFiltering]='false' [allowSorting]="false" [allowSearching]="false" [allowEditing]="false" [showInColumnChooser]='false' width='50'>
                <ng-template #template let-dataContacts> 
                   <input type="image" src="assets/icons/deal-icon.png" (click)="enque($event, dataContacts, 'deal')" style="width:22px;" />
                </ng-template>
            </e-column> 
        <e-column field='first_name' headerText='First Name' width='120'></e-column>
        <e-column field='last_name' headerText='Last Name' width='150' ></e-column>
        <e-column field='mobile' headerText='Mobile Phone' width='130' ></e-column>
        <e-column field='email' headerText='Email' width='120' ></e-column>
        <e-column field='company' headerText='Company' width='120' ></e-column>
        <e-column field='eng_ranking' headerText='Engagement' width=120 [allowFiltering]='false' [allowEditing]="false" >
            <ng-template #template let-dataContacts>
                    <div class="statustemp"><span class="statustxt">{{dataContacts.eng_ranking}}</span></div>
                </ng-template>
        </e-column>
    </e-columns>
</ejs-grid>

 