https://ej2.syncfusion.com/angular/demos/#/bootstrap5/inplace-editor/form
<div id='container'>
    <div class="control-group">
        <div class="e-heading">
        <h3> Create Product Listing for approval</h3>
        </div>
        <table class="create-product-table">
            <tr>
                <td >Product Name</td>
                <td class='left'>
                    <ejs-inplaceeditor #prdName id="prdName" mode="Inline" value="" [model]="prdNameModel"></ejs-inplaceeditor>
                </td>
            </tr>
            <tr>
                <td>Title</td>
                <td class='left'>
                    <ejs-inplaceeditor #prdTitle id="prdTitle" mode="Inline" value="" [model]="prdTitleModel"></ejs-inplaceeditor>
                </td>
            </tr>
            <tr>
                <td>Sub Title</td>
                <td class='left'>
                    <ejs-inplaceeditor #prdSTitle id="prdSTitle" mode="Inline" value="" [model]="prdSTitleModel"></ejs-inplaceeditor>
                </td>
            </tr>
            <tr>
                <td>Description</td>
                <td class='left'>
                    <ejs-inplaceeditor #prdDesc id="prdDesc" mode="Inline" value="" [model]="prdDescModel" type="RTE"></ejs-inplaceeditor>
                </td>
            </tr>
            <tr>
                <td>Button Text</td>
                <td class='left'>
                    <ejs-inplaceeditor #prdBtnTxt id="prdBtnTxt" mode="Inline" value="" [model]="prdBtnTxtModel"></ejs-inplaceeditor>
                </td>
            </tr>
            <tr>
                <td>Button URL/Target</td>
                <td class='left'>
                    <ejs-inplaceeditor #prdBtnURL id="prdBtnURL" mode="Inline" value="" [model]="prdBtnURLModel"></ejs-inplaceeditor>
                </td>
            </tr>
            <tr>
                <td>Image URL</td>
                <td class='left'>
                    <ejs-inplaceeditor #prdImgURL id="prdImgURL" mode="Inline" value="" [model]="prdImgURLModel"></ejs-inplaceeditor>
                </td>
            </tr>
            <tr>
                <td></td>
                <td>
                    <button ejs-button cssclass='e-small' class='e-control e-btn e-lib e-small' #btn_create (click)="onCreateProduct()">Create new product</button>
                </td>
            </tr>
        </table>
    </div>
</div>
<ejs-toast #element (created)="onCreateToast($event)" [position] = 'position' ></ejs-toast>

