import { Component, OnInit } from '@angular/core';
import { Product } from 'src/models';
import { EventService} from '../event.service';
import { DataStore } from 'aws-amplify';
import { Router } from '@angular/router';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor(public EventService:EventService, private router: Router) { }
  public Products:Product[];

  ngOnInit(): void {
    this.EventService.createScreenChangeEvent("product");
    this.getProducts();
  }

  async getProducts() {
    this.Products = await DataStore.query(Product); 
  }

  onMyProduct() {
    this.router.navigate(['/productSubscription']);
  }

  onCreateProduct() {
    this.router.navigate(['/productManagement']);
  }
  /*async onCreateProduct() {
    var cognitoSubID:string = await this.EventService.localUserID;
    var cognitoTeamID:string = await this.EventService.localTeamID;
    
    try {
        const NewTeam = await DataStore.save(
          new Product ({
            description: "TidyEats - take on the world one bite at a time",
            create_id: cognitoSubID,
            create_team: cognitoTeamID,
            name: "TidyEats",
            imageURL: "https://zepys.com/wp-content/uploads/2018/07/zepys_logo_tn-2.png",
            btnText: "Click Me!",
            btnURL:"https://google.com",
            title: "Title",
            subTitle: "Sub Title"
          })
        );
    } catch (error) {
        console.log("Error adding product", error);
      }


  }*/
  
}
