<br>
<ejs-grid #grid [dataSource]='dataProductSubscriptions' 
                allowSorting='true' 
                allowPaging='false' 
                [pageSettings]='this.pageSettings' 
                [toolbar]='this.toolbar' 
                [sortSettings]='this.initialSort' 
                [editSettings]='this.editSettings' 
                (actionComplete)='OnDataChange($event)' 
                allowReordering='true' 
                showColumnChooser='true' 
                [allowResizing]='true'>
    <e-columns>
        <e-column field='title' headerText='Title' width='120'></e-column>
        <e-column field='name' headerText='Name' width='130' ></e-column>
        <e-column field='subTitle' headerText='Sub-Title' width='130' ></e-column>
        <e-column field='description' headerText='Description' width='150' ></e-column>
    </e-columns>
</ejs-grid>
