<br>
<div class="property-panel-content">
    <table class="e-filter-table">
        <tr>
            <td>
                <div>
                    <ejs-textbox id="search_text" #search_text placeholder="Enter search text" [showClearButton]="true" (focus)="onFocus()" (keyup)="searchClick($event)"></ejs-textbox>
                </div>
            </td>
        </tr>
    </table>
</div>
<br>
<ejs-kanban #kanbanObj keyField='status' [dataSource]='dealData' [cardSettings]='cardSettings'
  [swimlaneSettings]='swimlaneSettings'
  (actionComplete)='OnDataChange($event)' 
  [dialogSettings]='dialogSettings'
  [sortSettings]='sortSettings'>
    <e-columns>
        <e-column headerText='Lead' keyField='lead' allowToggle='true'></e-column>
        <e-column headerText='MQL' keyField='mql' allowToggle='true'></e-column>
        <e-column headerText='SQL' keyField='sql' allowToggle='true'></e-column>
        <e-column headerText='Opportunity' keyField='opp' allowToggle='true'></e-column>
    </e-columns>
    <ng-template #cardSettingsTemplate let-data>
      <div class='e-card-content'>
          <table class="card-template-wrap" width=100%>
              <tbody>
                  <tr>
                      <td class="CardHeader">Full Name:</td>
                      <td>{{data.first_name}} {{data.last_name}}</td>
                  </tr>
                  <tr>
                      <td class="CardHeader">Company:</td>
                      <td>{{data.company}}</td>
                  </tr>
                  <tr>
                    <td class="CardHeader">Due Date:</td>
                    <td>{{data.dueDate}}</td>
                </tr>
                  <tr>
                      <td class="CardHeader">Summary:</td>
                      <td>{{data.description}}</td>
                  </tr>
              </tbody>
          </table>
      </div>
  </ng-template>

  <ng-template #dialogSettingsTemplate let-data>
    <table>
        <tbody>
            <tr>
                <td class="e-label">Description</td>
                <td>
                   <div class="e-float-input e-control-wrapper">
                        <textarea type="text" name="description" id="description" class="e-field"
                        value='{{data.description}}'></textarea>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="e-label">Due Date:</td>
                <td>
                   <div class="e-float-input e-control-wrapper">
                        <ejs-datetimepicker #dealDueDate name="dueDate" id="dueDate" [value]='data.dueDate' (change)="OnDateChange($event)" format="dd-MMM-yy hh:mm aa"></ejs-datetimepicker>
                    </div>
                </td>
            </tr>
            <tr>
            <td class="e-label">Target Value</td>
                <td>
                   <div class="e-float-input e-control-wrapper">
                        <input type="number" name="targetValue" id="targetValue" class="e-field" min="1"
                        value="{{data.targetValue || 0}}">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
  </ng-template>

</ejs-kanban>
