import { Component, OnInit, ViewEncapsulation, NgZone, ViewChild, AfterViewInit, AfterContentInit, AfterContentChecked, ViewChildren, QueryList } from '@angular/core';
import { DataStore } from 'aws-amplify';
import { EventType, UserEvent } from 'src/models';
import { EventService} from '../event.service';
import { ReportingService } from '../reporting.service';
import { Browser } from '@syncfusion/ej2-base';
import { Category, ChartAllModule, ChartComponent, ChartModule, ChartTheme, ColumnSeries, DateTimeCategoryService, DateTimeService, ILoadedEventArgs, LineSeriesService, StripLineService } from '@syncfusion/ej2-angular-charts';

@Component({
  
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ DateTimeService, LineSeriesService, DateTimeCategoryService, StripLineService],
})

export class DashboardComponent implements AfterViewInit {
  public contactDataAWS: any[];
  @ViewChild('chart_contacts', {static: false}) public chart_contacts: ChartComponent;
  //public Charts: QueryList<ChartComponent>
  //private ContactChart: ChartComponent

  public cellSpacing: number[] = [10, 10];

  public panels: any = [
    { "sizeX": 3, "sizeY": 2, "row": 0, "col": 0, content: '<div class="col-lg-12 col-md-12 col-sm-12 dashboard-control"><div class="e-card grid-container"><div class="e-card-header"><div class="e-card-header-caption"><div class="e-card-header-title"><span class="label-text">Team Activity</span></div></div></div><div class="e-card-content"><ejs-grid [dataSource]="gridData" height="160px"><e-columns><e-column field="Time" width="80" textAlign="Left"></e-column><e-column field="Name" width="120" textAlign="Left"></e-column><e-column field="DoctorName" headerText="Doctor Name" width="150" textAlign="Left"><ng-template #template let-data><a routerLink="/leads/{{data.DoctorId}}">{{data.DoctorName}}</a></ng-template></e-column><e-column field="Symptoms" width="150" textAlign="Left" clipMode="EllipsisWithTooltip"></e-column></e-columns></ejs-grid></div></div></div>'},
    { "sizeX": 1, "sizeY": 1, "row": 2, "col": 0, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card week-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Size</span><div class="count-container"> <span class="icon-week"><img src="/assets/icons/ThisWeek_Widget.svg" alt="ThisWeek" /></span><span class="week-event-count"></span><span class="text-display">Revenue</span></div></div></div></div>'},
    { "sizeX": 1, "sizeY": 1, "row": 2, "col": 1, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card day-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Quantity</span><div class="count-container"><span class="icon-day"><img src="/assets/icons/Today_Widget.svg" alt="Today" /></span><span class="day-event-count"></span><span class="text-display">Tasks & Opportunities by stage</span></div></div></div></div>'},
    { "sizeX": 1, "sizeY": 1, "row": 2, "col": 2, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card day-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Velocity</span><div class="count-container"><span class="icon-day"><img src="/assets/icons/Today_Widget.svg" alt="Today" /></span><span class="day-event-count"></span><span class="text-display">How quickly do Opportunities move through the funnel</span></div></div></div></div>'},
    { "sizeX": 1, "sizeY": 1, "row": 3, "col": 0, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card week-events-container"><div style="width:100%;height:100%; "class="e-card-content"><span class="card-text label-text">Quality</span><div class="count-container"><span class="icon-week"><img src="/assets/icons/ThisWeek_Widget.svg" alt="ThisWeek" /></span><span class="week-event-count"></span><span class="text-display">Effort and cost vs revenue</span></div></div></div></div>'},
    { "sizeX": 1, "sizeY": 1, "row": 3, "col": 1, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card week-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Close</span><div class="count-container"><span class="icon-week"><img src="/assets/icons/ThisWeek_Widget.svg" alt="ThisWeek" /></span><span class="week-event-count"></span><span class="text-display">Percent of active Opportunities that close in the period</span></div></div></div></div>' },
    { "sizeX": 1, "sizeY": 1, "row": 3, "col": 2, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display">Contact growth</div>' },
    { "sizeX": 1, "sizeY": 1, "row": 4, "col": 0, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card week-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Customer Aquisition Cost</span><div class="count-container"><span class="icon-week"><img src="/assets/icons/ThisWeek_Widget.svg" alt="ThisWeek" /></span><span class="week-event-count"></span><span class="text-display">Customer Aquisition Cost:</span></div></div></div></div>' },
    { "sizeX": 1, "sizeY": 1, "row": 4, "col": 1, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card week-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Sales Opportunities and Leads </span><div class="count-container"><span class="icon-week"><img src="/assets/icons/ThisWeek_Widget.svg" alt="ThisWeek" /></span><span class="week-event-count"></span><span class="text-display">Sales Opportunities and Leads</span></div></div></div></div>' },
    { "sizeX": 1, "sizeY": 1, "row": 4, "col": 2, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card week-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Monthly Sales Growth</span><div class="count-container"><span class="icon-week"><img src="/assets/icons/ThisWeek_Widget.svg" alt="ThisWeek" /></span><span class="week-event-count"></span><span class="text-display">Monthly Sales Growth</span></div></div></div></div>' },
    { "sizeX": 1, "sizeY": 1, "row": 5, "col": 0, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card week-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Customer Lifetime value</span><div class="count-container"><span class="icon-week"><img src="/assets/icons/ThisWeek_Widget.svg" alt="ThisWeek" /></span><span class="week-event-count"></span><span class="text-display">Customer Lifetime value</span></div></div></div></div>' },
    { "sizeX": 1, "sizeY": 1, "row": 5, "col": 1, content: '<div style="width:100%;height:100%;" class="col-lg-6 col-md-6 col-sm-6 text-display"><div style="width:100%;height:100%;" class="e-card card week-events-container"><div style="width:100%;height:100%;" class="e-card-content"><span class="card-text label-text">Sales Cycle Length</span><div class="count-container"><span class="icon-week"><img src="/assets/icons/ThisWeek_Widget.svg" alt="ThisWeek" /></span><span class="week-event-count"></span><span class="text-display">Sales Cycle Length</span></div></div></div></div>' }

  ];

  //Demo objects


  public data: Object[] = [
    { Period : new Date(2012, 6, 11), Can_Growth : 13.5, Viet_Growth : 5.3, Mal_Growth : 5.6, Egy_Growth : 6.6, Ind_Growth : 2.3 },
    { Period : new Date(2013, 6, 11), Can_Growth : 12.4, Viet_Growth : 5.6, Mal_Growth : 4.7, Egy_Growth : 6.8, Ind_Growth : 2.6 },
    { Period : new Date(2014, 6, 11), Can_Growth : 12.7, Viet_Growth : 5.9, Mal_Growth : 4.3, Egy_Growth : 6.5, Ind_Growth : 4.4 },
    { Period : new Date(2015, 6, 11), Can_Growth : 12.5, Viet_Growth : 5.7, Mal_Growth : 3.8, Egy_Growth : 5.5, Ind_Growth : 4.9 },
    { Period : new Date(2016, 6, 11), Can_Growth : 12.7, Viet_Growth : 7.8, Mal_Growth : 2.8, Egy_Growth : 5.0, Ind_Growth : 4.8 },
    { Period : new Date(2017, 6, 11), Can_Growth : 13.7, Viet_Growth : 10.3, Mal_Growth : 2.8, Egy_Growth : 6.8, Ind_Growth : 5.3 },
    { Period : new Date(2018, 6, 11), Can_Growth : 13.4, Viet_Growth : 15.5, Mal_Growth : 4.1, Egy_Growth : 7.8, Ind_Growth : 6.2  },
    { Period : new Date(2019, 6, 11), Can_Growth : 12.9, Viet_Growth : 17.5, Mal_Growth : 6.8, Egy_Growth : 7.3, Ind_Growth : 7.8 },
    { Period : new Date(2020, 6, 11), Can_Growth : 11.0, Viet_Growth : 19.5, Mal_Growth : 7.1, Egy_Growth : 8.2, Ind_Growth : 9.3 },
];

public data_contacts: Object[] = [];

//Initializing Primary X Axis
public primaryXAxis: Object = {
    valueType: 'DateTime',
    intervalType: 'Auto',
    edgeLabelPlacement: 'Shift',
    majorGridLines: { width: 0 },
    labelFormat: 'd MMM y'
};
//Initializing Primary Y Axis
public primaryYAxis: Object = {
    title: 'Count',
    //minimum: 0,
    //maximum: 20,
    intervalType: 'Auto',
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 }
};

public chartArea: Object = {
    border: {
        width: 0
    }
};

public width: string = Browser.isDevice ? '100%' : '100%';
public circleMarker: Object = { visible: true, height: 7, width: 7 , shape: 'Circle' , isFilled: true };
public triangleMarker: Object = { visible: true, height: 6, width: 6 , shape: 'Triangle' , isFilled: true };
public diamondMarker: Object = { visible: true, height: 7, width: 7 , shape: 'Diamond' , isFilled: true };
public rectangleMarker: Object = { visible: true, height: 5, width: 5 , shape: 'Rectangle' , isFilled: true };
public pentagonMarker: Object = { visible: true, height: 7, width: 7 , shape: 'Pentagon' , isFilled: true };

public tooltip: Object = {
    enable: true
};
public legend: Object = {
    visible: true,
    enableHighlight : true
}
  //End demo objects

  constructor(private ngZone: NgZone, private EventService:EventService, private ReportingService:ReportingService) { }

  async ngAfterViewInit() {
    //this.getActiveContacts();
    await this.EventService.ngOnInit();
    
    await this.ReportingService.updateData();
    this.data_contacts = this.ReportingService.data_contacts;
    
    //todo fix the busted refresh/reload bug - need to initalise the ngZone...
    this.ngZone.run(() => {;});
    //this.EventService.ngOnInit();
  
    this.chart_contacts.refresh();
    
  }

/*
//This function backfills data for contacts, this is also done in the reporting service:
  async getActiveContacts() {
    var contactCountArray: number[] = [];
    var contactMonthArray: string[] = [];

    var today = new Date();
    var contactCount = 1;
    
    while (contactCount != 0) {
      //Setup Date range:
      var toMonth: number = today.getMonth() + 2;
      var strToMonth: string = toMonth.toString();
      if (toMonth < 10) {strToMonth = "0" + strToMonth;}
      var toDate: string = today.getFullYear().toString() + "-" + strToMonth + "-00T00:00:00.000Z";
      
      var fromMonth: number = today.getMonth()+1;
      var strFromMonth: string = fromMonth.toString();
      if (fromMonth < 10) {strFromMonth = "0" + strFromMonth;}
      var fromDate: string = today.getFullYear().toString() + "-" + strFromMonth + "-00T00:00:00.000Z";
      
      //Move the tracked date back by a month:
      today.setMonth(today.getMonth() - 1);
      
      this.contactDataAWS = await DataStore.query(UserEvent, (event) => 
      event.event_type("eq", EventType.CONTACT).team_id("eq",this.EventService.localTeamID).createdOn("gt",fromDate).createdOn("lt",toDate) );
      
      contactCountArray.push(this.contactDataAWS.length);
      contactCount = this.contactDataAWS.length;

      contactMonthArray.push(fromDate.substring(0,7));
      //this.data_contacts.push({ Period : new Date(fromDate), contacts : 5 });
      //console.log(contactCountArray);
      //console.log(contactMonthArray);
      //console.log(this.contactDataAWS);
    }

    
  }
*/
}
