import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-edit-lead',
  templateUrl: './add-edit-lead.component.html',
  styleUrls: ['./add-edit-lead.component.scss']
})
export class AddEditLeadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showDetails() {
    //Invoked on edit lead
    console.log("open");
  }

  onAddLead() {
    //Invoked on add lead
    console.log("add/edit");
  }
  
}