<br>
<table>
    <tr>
        <td valign="top">
<table>
    <tr>
        <td>
            <div class="treeview-container">
                <div class="title-container">
                <h1 class="title-text">SMS to be scheduled
                </h1>
                </div>
                <ejs-treeview #toScheduleCallsList [fields]='field' cssClass='treeview-external-drag' dragArea=".drag-sample-wrapper"
                [allowDragAndDrop]='allowDragAndDrop' (nodeDragStop)="onTreeDragStop($event)" (nodeDragStart)="onTreeDragStart()"
                (nodeDragging)="onTreeDrag($event)" (nodeSelecting)="onItemSelecting($event)">
                <ng-template #nodeTemplate let-data>
                    <div id="toschedule">
                    <div id="toscheduledetails">
                        <div id="toschedulelist">{{data.Name}}</div>
                        <div id="toscheduledetails">-Details-</div>
                    </div>
                    </div>
                </ng-template>
                </ejs-treeview>
            </div>
        </td>
    </tr>
</table>
</td>
<td>
<table>
    <tr>        
        <td valign="top">
            <div class="treeview-container">
                <div class="title-container">
                <h1 class="title-text">Meetings to be scheduled
                </h1>
                </div>
                <ejs-treeview #toScheduleMeetingList [fields]='field' cssClass='treeview-external-drag' dragArea=".drag-sample-wrapper"
                [allowDragAndDrop]='allowDragAndDrop' (nodeDragStop)="onTreeDragStop($event)" (nodeDragStart)="onTreeDragStart()"
                (nodeDragging)="onTreeDrag($event)" (nodeSelecting)="onItemSelecting($event)">
                <ng-template #nodeTemplate let-data>
                    <div id="toschedulemeetings">
                    <div id="toschedulemeetingdetails">
                        <div id="first_name"><b>{{data.first_name}} {{data.last_name}}</b></div>
                        <div id="company">{{data.company}}</div>
                        <div id="toschedulemeetingdetails">{{data.description}}</div>
                    </div>
                    </div>
                </ng-template>
                </ejs-treeview>
            </div>
        </td>
    </tr>
</table>
</td>
<td valign="top">
<table>
    <tr>        
        <td>
            <div class="treeview-container">
                <div class="title-container">
                <h1 class="title-text">Email to be scheduled
                </h1>
                </div>
                <ejs-treeview #toScheduleEmailList [fields]='field' cssClass='treeview-external-drag' dragArea=".drag-sample-wrapper"
                [allowDragAndDrop]='allowDragAndDrop' (nodeDragStop)="onTreeDragStop($event)" (nodeDragStart)="onTreeDragStart()"
                (nodeDragging)="onTreeDrag($event)" (nodeSelecting)="onItemSelecting($event)">
                <ng-template #nodeTemplate let-data>
                    <div id="toschedule">
                    <div id="toscheduledetails">
                        <div id="toschedulelist">{{data.Name}}</div>
                        <div id="toscheduledetails">-Details-</div>
                    </div>
                    </div>
                </ng-template>
                </ejs-treeview>
            </div>
        </td>
    </tr>
</table>
</td>
</tr>
</table>

<ejs-schedule #scheduleObj 
    [eventSettings]="eventSettings" 
    startHour='08:00'
    endHour='21:00' 
    [firstDayOfWeek]='1' 
    [views]="scheduleViews" 
    (actionComplete)="onDataChange($event)"
    [dateFormat]="dateFormat"
    [allowDragAndDrop]=true 
    [allowResizing]=true> 
</ejs-schedule>
