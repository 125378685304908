import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActionEventArgs, ColumnChooserService, EditService, GridComponent, PageService, ReorderService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { DataStore } from 'aws-amplify';

@Component({
  selector: 'app-product-subscription',
  templateUrl: './product-subscription.component.html',
  styleUrls: ['./product-subscription.component.scss'],
  providers: [ToolbarService, EditService, PageService, SortService, ReorderService, ColumnChooserService ],
})
export class ProductSubscriptionComponent implements OnInit {

  @ViewChild('grid') gridObj: GridComponent;
  
  public dataProductSubscriptions: Object[];
  public pageSettings: Object;
  public toolbar: string[];
  public initialSort: Object;  
  public editSettings: Object;
  public progressrules: Object;
  public numericParams: Object;


  
  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
    this.pageSettings = {  pageSizes: true, pageCount: 5 };
    this.progressrules = { number: true , min: 0};
    this.numericParams = { params: {  format: 'n' } };
    this.initialSort = {
      columns: [{ field: 'title', direction: 'Ascending' }]
    };
    
    this.editSettings = { allowEditing: false, allowAdding: false, allowDeleting: false, mode: 'Normal' };
    this.toolbar = ['Search', 'ColumnChooser'];
      
  }

OnDataChange(state: ActionEventArgs)
{
  switch(state.requestType) {
    case 'delete':
      
      break;
    case 'save':
      if ((state as any).action == "add") {
        //this.addContactObject(state.data);
      } else {
        //this.saveContactObject(state.data);
      }
      break;
    case 'beginEdit': break; //Do nothing 
    case 'refresh': break; //Do nothing 
    case 'add': break; //Do nothing 
    case 'sorting': break; //Do nothing 
    case 'paging': break; //Do nothing 
    case 'refresh': break; //Do nothing 
    case 'searching': break; //Do nothing
    case 'reorder': break; //Do nothing
    default:
      console.log("New request Type:");
      console.log(state);
  }
}

async gridRefresh() {
  //this.dataProductSubscriptions = await DataStore.query(Contact, c => c.team_id("eq", this.teamID)); 
  this.gridObj.autoFitColumns();
  this.ngZone.run(() => {this.gridObj.refresh();});
}



}
