import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';

//registerLicense('Mgo+DSMBaFt/QHRqVVhlWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTH9RdkFgXHxYdn1VQg==;Mgo+DSMBPh8sVXJ0S0J+XE9BclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Tc0RhWXtddXdTQWBfVQ==;ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkBiW39ac3FWQGddVEA=;MjAxOTYxMkAzMjMwMmUzNDJlMzBUQkxCZ0U1bGRGTkswTWZiMG94T2hhZDRGeitSRzVYZFNnMmF3Qm1lR0d3PQ==;MjAxOTYxM0AzMjMwMmUzNDJlMzBBcTdFdmZFTFZqNDR1M3o0b1lJazNOYXdGVG9RK1JmSG45Mm4vVURUUUwwPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVmWH1edHdQRWZbVkxy;MjAxOTYxNUAzMjMwMmUzNDJlMzBjYjE4QVFNUUxIVHlKalBRZGM2Q2FLMkZ4V2t6SGhmQ0NFMG9pUjJvWkdVPQ==;MjAxOTYxNkAzMjMwMmUzNDJlMzBiWXI3UnNjdXloVitqNFBCYkcxOUg4UnJyM1FTSnZaWWd1Rkp6akFIMTFZPQ==;Mgo+DSMBMAY9C3t2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkBiW39ac3FWQGhYWUA=;MjAxOTYxOEAzMjMwMmUzNDJlMzBZYXF2bFg5SUFWZTJYSUl2SXlqOStJcXBITHBsTWgxakNuTmwrQTFoY1hJPQ==;MjAxOTYxOUAzMjMwMmUzNDJlMzBGaGs0aEFMeDRrMUkwdUdLMFdmT1V5Y3RlZFdUTGhWdCt6UGc3Tm16SDNvPQ==;MjAxOTYyMEAzMjMwMmUzNDJlMzBjYjE4QVFNUUxIVHlKalBRZGM2Q2FLMkZ4V2t6SGhmQ0NFMG9pUjJvWkdVPQ==');
registerLicense('ORg4AjUWIQA/Gnt2VFhiQlRPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtRckRrXHpfdHddTmI=');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
