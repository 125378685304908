import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Auth } from 'aws-amplify';
import { EmitType } from '@syncfusion/ej2-base';
import { PopProfileComponent } from '../pop-profile/pop-profile.component';
import { EventService } from '../event.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  
    @ViewChild('popProfileObj') public popProfileObj: PopProfileComponent;
    userattributes: any;

    constructor(private appService:EventService) { }
    
    async ngOnInit(): Promise<void> {
      const user = await Auth.currentAuthenticatedUser({bypassCache: true});
      this.userattributes = user.attributes;
      
      this.appService.updateProfileName((this.userattributes["given_name"] || '') + ' ' + (this.userattributes["family_name"] || ''));
      this.appService.currentProfileName.subscribe(profileName => document.querySelector('.profile-name')!.textContent = profileName );

      this.appService.updateEmailAddress(this.userattributes["email"]);
      this.appService.currentemailAddress.subscribe(email => document.querySelector('.email')!.textContent = email);
      
      this.appService.updateCurrentTeamName(this.userattributes["custom:selectedTeam"]);
      this.appService.updateCurrentTeamID(this.userattributes["custom:selectedTeamID"]);
      this.appService.currentTeamName.subscribe(team => document.querySelector('.team')!.textContent = team);
      

      if (this.userattributes["picture"]) {
        //(<HTMLImageElement>document.getElementById('profilePic'))!.src = "https://zepys4d72c29f218047e292243de5629c2a51155626-dev.s3.ap-southeast-2.amazonaws.com/public/" + this.userattributes["picture"];
        (<HTMLImageElement>document.getElementById('profilePic'))!.src = "https://zepys4d72c29f218047e292243de5629c2a51194654-develop.s3.ap-southeast-2.amazonaws.com/public/" + this.userattributes["picture"];
      }
  }

  signOut($event) {
    Auth.signOut();
    location.reload();
  }

  async onProfileChanged(changed: boolean) {
    document.querySelector('.profile-name')!.textContent = (this.popProfileObj.firstNameVar || '') + ' ' + (this.popProfileObj.lastNameVar || '');
    document.querySelector('.email')!.textContent = this.popProfileObj.emailVar || '';
    console.log(this.userattributes);
    document.querySelector('.team')!.textContent = this.userattributes['custom:selectedTeam'] || '';
  }

  onProfileClick($event) {   
    this.popProfileObj.onEditProfile();
  }

}
