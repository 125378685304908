import { Injectable } from '@angular/core';
import { DataStore } from 'aws-amplify';
import { Contact, ReportData, ReportSettings, Team } from 'src/models';
import { EventService} from './event.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  private LastRun: Date;
  private NextRun: Date;
  private TickerDuration: string;
  public data_contacts: Object[] = [];

  
  constructor(public EventService:EventService) { 
    
  }

  async updateData() {
    //Get next run date versus now.
    var rptSettings = await DataStore.query(ReportSettings, settings => settings.team_id("eq",this.EventService.localTeamID));

    console.log("Report Settings are: ");
    console.log(rptSettings);
   
    if (rptSettings.length == 0) { 
      await this.generateDefaultReportSettings(); 
    } else {
      this.TickerDuration=rptSettings[0].duration;
      this.LastRun=new Date(rptSettings[0].statsLastRan);
      this.NextRun=new Date(rptSettings[0].statsNextRun);
    }
  
    var now = new Date();
    if (this.NextRun<now) {
      //If run overdue then build stats:
      this.updateStats();
    }

    this.populateData_ContactsArray();
  }

  private async populateData_ContactsArray() {
    var contactData = await DataStore.query(ReportData, report => report.team_id("eq",this.EventService.localTeamID).and(report => report.report("eq","ActiveContacts")).and(report => report.duration("eq",this.TickerDuration)), {sort: report => report.dateTime("ASCENDING")});
    
    console.log("This is the contact data:");
    console.log(contactData);
    console.log(ReportSettings);

    for(let x=contactData.length; x<contactData.length;x++) {
      this.data_contacts.push({ Period : new Date(contactData[x].dateTime), contacts : contactData[x].value });
      console.log(contactData[x]);
    }

  }

  private async generateDefaultReportSettings() {

    
    //Should only be called when the team has no report settings.
    //Get team creation date:
    var teamInformation = await DataStore.query(Team, team => team.id("eq",this.EventService.localTeamID));
    this.NextRun =new Date(teamInformation[0].createdOn.toString());
    this.LastRun = new Date(teamInformation[0].createdOn.toString());
    this.TickerDuration = "day";

    await DataStore.save(
      new ReportSettings ({
          create_id: this.EventService.localUserID,
          team_id: this.EventService.localTeamID,
          statsLastRan: teamInformation[0].createdOn,
          statsNextRun: teamInformation[0].createdOn,
          dataActiveContactsEnabled: true,
          duration: "day",
          
          /*String #Tick duration. can be hour, day, month
          #the report type. Can be Revenue, QtyTasksToDo, QtyTasksInProg, QtyTasksClosed,  QtyDealsLead, QtyDealsMQL, QtyDealsSQL, QtyDealsOpp, ActiveContacts, CAC, SalesGrowth, SalesLength   
          dataQtyRevenueEnabled: Boolean
          dataQtyTasksToDoEnabled: Boolean
          dataQtyTasksInProgEnabled: Boolean
          dataQtyTasksClosedEnabled: Boolean
          dataQtyDealsLeadEnabled: Boolean
          dataQtyDealsMQLEnabled: Boolean
          dataQtyDealsSQLEnabled: Boolean
          dataQtyDealsOppEnabled: Boolean
          dataActiveContactsEnabled: Boolean
          dataDealLengthLeadMQLEnabled: Boolean
          dataDealLengthMQLSQLEnabled: Boolean
          dataDealLengthSQLOppEnabled: Boolean
          dataDealLengthOppCloseEnabled: Boolean
          dataCACEnabled: Boolean
          dataSalesGrowthEnabled: Boolean */
        })
      );

     
  }

  private updateStats() {
    /* Which stats need updating:
    dataQtyRevenueEnabled
    dataQtyTasksToDoEnabled
    dataQtyTasksInProgEnabled
    dataQtyTasksClosedEnabled
    dataQtyDealsLeadEnabled
    dataQtyDealsMQLEnabled
    dataQtyDealsSQLEnabled
    dataQtyDealsOppEnabled
    dataActiveContactsEnabled
    dataDealLengthLeadMQLEnabled
    dataDealLengthMQLSQLEnabled
    dataDealLengthSQLOppEnabled
    dataDealLengthOppCloseEnabled
    dataCACEnabled
    dataSalesGrowthEnabled*/

    this.updatedataActiveContacts();
    this.setNextRunDate();
  }

  private async updatedataActiveContacts() {
      console.log("Updating contact stats since: " + this.LastRun.toISOString());

      //setup the loop:
      var thisRun = this.LastRun; //thisRun is the iterator and is updated
      var now = new Date();
      var thisRunStr = thisRun.toISOString();
      var thisRunCount= 30;

      try {
 
        while(thisRun <= now && thisRunCount > 0) {
          
          thisRunCount -=1;

          switch (this.TickerDuration) {
            case "hour":
              thisRun.setHours(thisRun.getHours() + 1); //Shift now forward by a tick so we can see the most up to date data
              break;
            case "day":
              thisRun.setDate(thisRun.getDate() + 1); //Shift now forward by a tick so we can see the most up to date data
              break;
            default: //if something goes wrong assume it is a day
              thisRun.setDate(thisRun.getDate() + 1); //Shift now forward by a tick so we can see the most up to date data
              break;
          } 
         var thisRunStr = thisRun.toISOString();
        
         console.log("This run is:" + thisRun.toDateString());

          var contactCount = await DataStore.query(Contact, 
          contacts => contacts.team_id("eq",this.EventService.localTeamID).
                and(contacts => contacts.createdOn("lt",thisRunStr)));

          //Update the report stat:
          await DataStore.save(
            new ReportData ({
              create_id: this.EventService.localUserID,
              team_id: this.EventService.localTeamID,
              report: "ActiveContacts",
              duration: this.TickerDuration,
              dateTime: thisRunStr,
              value: contactCount.length
            })
          );

        }
      
      } catch (error){
        console.log("Error getting active contacts report data ", error);
      }
  }

  private async setNextRunDate() {
      
      //Get the current object:
      const original = await DataStore.query(ReportSettings, ReportSettings => ReportSettings.team_id("eq", this.EventService.localTeamID));
      
      if (original.length == 0) {
  //      console.log("This team has no report settings");  
      } else {
        /*
        console.log("Updating report settings");
        console.log(original);
        */
        var nextRun = new Date();

      //Update the report stat:
                    await DataStore.save(
                      ReportSettings.copyOf(original[0], updated => {
                        updated.statsLastRan = nextRun.toISOString(),
                        updated.statsNextRun = nextRun.toISOString()
                      })
                    );
      }
  }
}
