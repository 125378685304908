import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { onAuthUIStateChange, CognitoUserInterface, AuthState, FormFieldTypes, PhoneFormFieldType } from '@aws-amplify/ui-components';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  title = 'zepys';
  authState!: AuthState;
  user: CognitoUserInterface | undefined;
  formFields: FormFieldTypes;
  
  constructor(private ref: ChangeDetectorRef) {
    this.formFields = [
      {
        type: 'email',
      },
      {
        type: 'password',
      },
      {
        type: 'phone_number',
        dialCode: '+61',
        hint: '',
      } as PhoneFormFieldType,
    ];
  }

  ngOnInit() {
    
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
    });
}

facebookLogin(event: MouseEvent) {
  Auth.federatedSignIn({
       provider: CognitoHostedUIIdentityProvider.Facebook
  });
}
googleLogin(event: MouseEvent) {

  Auth.federatedSignIn({
     provider: CognitoHostedUIIdentityProvider.Google
  });
}

userDetails(event: MouseEvent) {
  Auth.currentAuthenticatedUser().then((user) => {
    console.log(user.attributes.given_name + ' ' + user.attributes.family_name + '\'s email = ' + user.attributes.email);
    console.log(user);
  });
}

}
