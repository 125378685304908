import { Component, OnInit, ViewChild, Output, ElementRef } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DataService } from '../data.service';
import { Auth, Hub } from 'aws-amplify';
import { EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {phoneNumberValidator} from '../common/validators'
import { EventService} from '../event.service';
//import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-pop-profile',
  templateUrl: './pop-profile.component.html',
  styleUrls: ['./pop-profile.component.scss']
})

export class PopProfileComponent implements OnInit {
  @ViewChild('editProfileObj') public editProfileObj: DialogComponent;
  
  //separateDialCode = false;
	/*SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;*/
  //PhoneNumberFormat = PhoneNumberFormat;
	
  
  showPhoto: boolean;
  imagePath: string;

  get firstName(): any {
    return this.profileForm.get('firstName');
  }

  get lastName(): any {
    return this.profileForm.get('lastName');
  }

  get email(): any {
    return this.profileForm.get('email');
  }

  get phone(): any {
    return this.profileForm.get('phone');
  }

  public animationSettings: Object = { effect: 'Zoom', duration: 400, delay: 0 };
  public title = 'Edit Profile';
  public doctorsData: { [key: string]: Object }[];
  public activeDoctorData: { [key: string]: Object; };

  public userattributes: any;
  public user: any;

  public firstNameVar: string = "";
  public lastNameVar: string = "";
  public emailVar: string = "";
  public phoneVar: string = "";
  public profilePic: string = "";

  //Setup the profile form
  profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['',Validators.required],
      email: ['', Validators.email],
      phone: new FormControl(undefined, [Validators.required])
    });


  constructor(private dataService: DataService, private fb: FormBuilder, private appService:EventService) {
    Hub.listen('storage', (data) => {
      if (data.payload.event === 'upload' ) {
        this.onImageUploaded(data);
       }
    })
   }

   async ngOnInit(): Promise<void> {
    this.showPhoto = false;
    this.setFormValues();
  }

  async setFormValues() {
    this.user = await Auth.currentAuthenticatedUser({bypassCache: true});
    this.userattributes = this.user.attributes;
    this.imagePath = 'profiles/' + this.user.username + '/';

    if (this.userattributes["picture"]) {
      this.profilePic = this.userattributes["picture"];
      this.showPhoto = true;
    }

    this.firstNameVar = this.userattributes["given_name"];
    this.lastNameVar = this.userattributes["family_name"];
    this.emailVar = this.userattributes["email"];
    this.phoneVar = this.userattributes["phone_number"];

    this.profileForm.setValue({
      firstName: this.firstNameVar,
      lastName: this.lastNameVar,
      email: this.emailVar,
      phone: this.phoneVar
    });

  }
  
  //Profile pic functions:
  async onImageUploaded(data) {
    let key: string = data.payload.message;
    key = key.substring(19);
    alert(key);
    key = key;
   alert(key);
   alert(this.imagePath);
    this.profilePic = key;
    this.showPhoto = true;
  }

  editPhoto() {
    this.showPhoto = false;
  }

  onEditPhone($event)
  {
    console.log("Phone edit");
    console.log($event);
    console.log(this);
    //test
  }
  
  onEditProfile() {
    this.setFormValues();
    this.editProfileObj.show();
  }

  onCancelClick($event) {
    this.resetFormFields();
    this.editProfileObj.hide();
  }

  onSaveClick() {

    if (this.profileForm.invalid) {
      //To do, make this prettier than an alert...:
      alert("You missed a spot. Please correct the gaps in your profile.");
    } else {
      this.firstNameVar = this.profileForm.get("firstName").value;
      this.emailVar = this.profileForm.get("email").value;
      this.lastNameVar = this.profileForm.get("lastName").value;
      this.phoneVar = this.profileForm.get("phone").value;
      var email_verified = this.userattributes["email_verified"];
      var phone_verified = this.userattributes["phone_verified"];

      //TODO manage verification code:
      //If email is changing set verified to false:
      if (this.emailVar != this.userattributes["email"]) {
        email_verified = false;
      }

      //TODO manage verification code
      //If phone is changing set verified to false:
      if (this.phoneVar != this.userattributes["phone_number"]) {
        phone_verified = false;
      }

      Auth.updateUserAttributes(this.user, {
              email: this.emailVar, 
              given_name: this.firstNameVar,
              family_name: this.lastNameVar, 
              email_verified: email_verified, 
              phone_number: this.phoneVar, 
              phone_verified: phone_verified,
              picture: this.profilePic
            });
              
      
      //Let the profile component know something has changed:
      this.appService.updateProfileName((this.firstNameVar || '') + ' ' + (this.lastNameVar || ''));
      this.appService.updateEmailAddress(this.emailVar);
      this.appService.updateProfilePic(this.profilePic);
      

      //Hide the form:
      this.editProfileObj.hide();  
    }
  }

  resetFormFields() {

  }
}
