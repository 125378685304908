
<br>
<br>
<a href="https://ej2.syncfusion.com/showcase/angular/appointmentplanner/#/dashboard">Syncfusion Demo</a><br>
<a href="https://ej2.syncfusion.com/angular/demos/?_gl=1*l41afb*_ga*MTU1NTgyODI1OS4xNzA2OTE2NzY2*_ga_41J4HFMX1J*MTcxNjI5NzY1NC4yMy4xLjE3MTYyOTc3ODAuMC4wLjA.#/bootstrap5/dashboard-layout/properties">Dashboard layout</a><br>
<a href="https://ej2.syncfusion.com/angular/documentation/dashboard-layout/getting-started">DashB Docs</a><br>
<a href="https://support.syncfusion.com/kb/article/9707/how-to-fit-charts-within-the-panels-of-dashboard-layout">Chart in a dashboard</a>
<br>
<br>

<ejs-dashboardlayout id="api_dashboard" #api_dashboard columns="6" [allowResizing]="false"  [cellSpacing]='cellSpacing'>
  <e-panels>
      <e-panel [sizeX]="4" [sizeY]="2" [row]="1" [col]="0">
          <ng-template #header>
              
          </ng-template>
          <ng-template #content>
              <div id="column">
                <ejs-chart #chart_contacts style='display:block'  align='center' id='chart_contacts' title="Contacts" [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
                [tooltip]='tooltip' [chartArea]='chartArea' [width]='width' [legendSettings]='legend'>
                <e-series-collection>
                    <e-series [dataSource]='data_contacts' type='Line' xName='Period' yName='contacts' name='contacts' width=1 opacity=1 > </e-series>
                </e-series-collection>
            </ejs-chart>
              </div>
          </ng-template>
      </e-panel>
 <!--     <e-panel [sizeX]="4" [sizeY]="2" [row]="2" [col]="0">
        <ng-template #header>
            
        </ng-template>
        <ng-template #content>
          <div id="column">
            <ejs-chart style='display:block' align='center' id='chartcontainer_revenue' title="Revenue" [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
            [tooltip]='tooltip' [chartArea]='chartArea' [width]='width' [legendSettings]='legend'>
            <e-series-collection>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Viet_Growth' name='Vietnam' width=2 [marker]='circleMarker' opacity=1> </e-series>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Can_Growth' name='Canada' width=2 [marker]='triangleMarker' opacity=1> </e-series>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Mal_Growth' name='Malaysia' width=2 [marker]='diamondMarker' opacity=1> </e-series>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Egy_Growth' name='Egypt' width=2 [marker]='rectangleMarker' opacity=1> </e-series>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Ind_Growth' name='Indonesia' width=2 [marker]='pentagonMarker' opacity=1> </e-series>    
            </e-series-collection>
        </ejs-chart>
          </div>
        </ng-template>
    </e-panel>
  -->
<!--
    <e-panel [sizeX]="2" [sizeY]="2" [row]="2" [col]="0">
      <ng-template #header>
          
      </ng-template>
      <ng-template #content>
          <div id="column">
            <ejs-chart style='display:block' align='center' id='chartcontainer_actions' title="Actions" [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
            [tooltip]='tooltip' [chartArea]='chartArea' [width]='width' [legendSettings]='legend'>
            <e-series-collection>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Viet_Growth' name='Meetings' width=2 [marker]='circleMarker' opacity=1> </e-series>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Can_Growth' name='Calls' width=2 [marker]='triangleMarker' opacity=1> </e-series>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Mal_Growth' name='Emails' width=2 [marker]='diamondMarker' opacity=1> </e-series>
                <e-series [dataSource]='data' type='Line' xName='Period' yName='Egy_Growth' name='SMS' width=2 [marker]='rectangleMarker' opacity=1> </e-series>   
            </e-series-collection>
        </ejs-chart>
          </div>
      </ng-template>
  </e-panel>
  
  <e-panel [sizeX]="2" [sizeY]="2" [row]="2" [col]="2">
    <ng-template #header>
        
    </ng-template>
    <ng-template #content>
        <div id="column">
          <ejs-chart style='display:block' align='center' id='chartcontainer_deals' title="Deals" [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
          [tooltip]='tooltip' [chartArea]='chartArea' [width]='width' [legendSettings]='legend'>
          <e-series-collection>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Viet_Growth' name='Lead' width=2 [marker]='circleMarker' opacity=1> </e-series>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Can_Growth' name='Marketing Qualified Lead' width=2 [marker]='triangleMarker' opacity=1> </e-series>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Mal_Growth' name='Sales Qualified Lead' width=2 [marker]='diamondMarker' opacity=1> </e-series>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Egy_Growth' name='Opportunity' width=2 [marker]='rectangleMarker' opacity=1> </e-series> 
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Ind_Growth' name='Committed' width=2 [marker]='pentagonMarker' opacity=1> </e-series>     
          </e-series-collection>
      </ejs-chart>
        </div>
    </ng-template>
</e-panel>

<e-panel [sizeX]="4" [sizeY]="2" [row]="3" [col]="0">
  <ng-template #header>
      
  </ng-template>
  <ng-template #content>
      <div id="column">
        <ejs-chart style='display:block' align='center' id='chartcontainer_stageTime' title="Time in stage" [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
        [tooltip]='tooltip' [chartArea]='chartArea' [width]='width' [legendSettings]='legend'>
        <e-series-collection>
            <e-series [dataSource]='data' type='Line' xName='Period' yName='Viet_Growth' name='Lead' width=2 [marker]='circleMarker' opacity=1> </e-series>
            <e-series [dataSource]='data' type='Line' xName='Period' yName='Can_Growth' name='Marketing Qualified Lead' width=2 [marker]='triangleMarker' opacity=1> </e-series>
            <e-series [dataSource]='data' type='Line' xName='Period' yName='Mal_Growth' name='Sales Qualified Lead' width=2 [marker]='diamondMarker' opacity=1> </e-series>
            <e-series [dataSource]='data' type='Line' xName='Period' yName='Egy_Growth' name='Opportunity' width=2 [marker]='rectangleMarker' opacity=1> </e-series> 
            <e-series [dataSource]='data' type='Line' xName='Period' yName='Ind_Growth' name='Committed' width=2 [marker]='pentagonMarker' opacity=1> </e-series>     
        </e-series-collection>
    </ejs-chart>
      </div>
  </ng-template>
</e-panel>
-->
    <!--<e-panel [sizeX]="4" [sizeY]="2" [row]="3" [col]="2">
      <ng-template #header>
        <div>Traffic History</div>
      </ng-template>
      <ng-template #content>
        <ejs-chart style="height:100%; width:100%" #lineChart id="visitorsChart" style='display:block;'
          [chartArea]='chartArea' [width]='widthValue' [height]='heightValue'
          [primaryXAxis]='lineprimaryXAxis' [primaryYAxis]='lineprimaryYAxis'
          [legendSettings]='linelegendSettings' (load)='load($event)'>
          <e-series-collection>
            <e-series [dataSource]='columnData' type='SplineArea' xName='x' yName='y' name='Jan'
              [border]='lineborder' fill='rgb(239, 183, 202)' opacity=0.5> </e-series>
            <e-series [dataSource]='columnData1' type='SplineArea' xName='x' yName='y' name='Feb'
              [border]='lineborder' fill='rgb(14, 64, 152, .6)' opacity=0.5> </e-series>
          </e-series-collection>
        </ejs-chart>
      </ng-template>
    </e-panel>
    <e-panel [sizeX]="4" [sizeY]="2" [row]="3" [col]="2">
      <ng-template #header>
        <div>Traffic History</div>
      </ng-template>
      <ng-template #content>
        <ejs-chart style="height:100%; width:100%" #lineChart id="visitorsChart" style='display:block;'
          [chartArea]='chartArea' [width]='widthValue' [height]='heightValue'
          [primaryXAxis]='lineprimaryXAxis' [primaryYAxis]='lineprimaryYAxis'
          [legendSettings]='linelegendSettings' (load)='load($event)'>
          <e-series-collection>
            <e-series [dataSource]='columnData' type='SplineArea' xName='x' yName='y' name='Jan'
              [border]='lineborder' fill='rgb(239, 183, 202)' opacity=0.5> </e-series>
            <e-series [dataSource]='columnData1' type='SplineArea' xName='x' yName='y' name='Feb'
              [border]='lineborder' fill='rgb(14, 64, 152, .6)' opacity=0.5> </e-series>
          </e-series-collection>
        </ejs-chart>
      </ng-template>
    </e-panel>-->
  </e-panels>
</ejs-dashboardlayout>

<style>
#columnChart,
#column {
  height: 100%;
  width: 100%;
}
</style>

<!--
<div class="control-section">
  <ejs-dashboardlayout id='defaultLayout' #defaultLayout 
      [columns]='4' 
      [cellAspectRatio]="3"
      [cellSpacing]='cellSpacing' 
      [allowFloating]='true'
      [allowResizing]='true'
      [panels]='panels'>
  </ejs-dashboardlayout>
</div>
-->