<br>
<button ejs-button cssclass='e-small' class='e-control e-btn e-lib e-small' #btn_myProducts (click)="onMyProduct()">My Products</button>
<br>
<button ejs-button cssclass='e-small' class='e-control e-btn e-lib e-small' #btn_create (click)="onCreateProduct()">Submit New Product for approval</button>
<div class="col-lg-12 control-section card-control-section vertical_card_layout">
  <div class="e-sample-resize-container e-card-resize-container">
      <div class='row'>
          <div class="row card-layout">
            
              <div *ngFor="let product of Products;" class="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                <div id='card_sample_{{product.id}}' class='card_sample'>
                  <div class="e-card e-card-horizontal">
                    <div class='e-card-image'>
                      <img src="{{product.imageURL}}">
                    </div>
                    <div class='e-card-header'>
                      <div class='e-card-header-caption'>
                        <div class='e-card-header-title'>{{product.title}}</div>
                        <div class='e-card-sub-title'>{{product.subTitle}}</div>
                      </div>
                    </div>
                    <div class='e-card-content' innerHtml="{{product.description}}"></div>
                    <div class='e-card-actions e-card e-card-horizontal'>
                      <a href='{{product.btnURL}}'>{{product.btnText}}</a>
                    </div>
                  </div>
                </div>
              </div>
                      
        </div>
    </div>
</div>