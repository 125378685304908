import { Component,ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { Browser } from '@syncfusion/ej2-base';
import { Auth } from 'aws-amplify';
import { DataService } from '../data.service';
import { EventService } from '../event.service';



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements AfterViewInit {
  @ViewChild('sideBar')
  public sideBar!: SidebarComponent;
  public showBackdrop: boolean = false;
  public closeOnDocumentClick: boolean = false;

  constructor(private ngZone: NgZone, private router: Router, public EventService: EventService) { 
    document.body.classList.add('main-page');
      if (Browser.isDevice) {
        this.showBackdrop = true;
        this.closeOnDocumentClick = true;
      }

      //setup roles:
      
  }
  ngAfterViewInit() {
    if (Browser.isDevice) {
      document.querySelector('.planner-header')!.classList.add('device-header');
      document.querySelector('.planner-wrapper')!.classList.add('device-wrapper');
    }
  }
 
  signOut($event) {
    Auth.signOut();
 }

  btnClick() {
    this.sideBar.show();
  }

  onItemClick(args: any, path: string) {

    if (Browser.isDevice) {
      //this.sideBar.hide();
    }
    const elements: HTMLElement[] = args.currentTarget.parentElement.querySelectorAll('.active-item');
    elements.forEach(element => {
      if (element.classList.contains('active-item')) { element.classList.remove('active-item'); }
    });
    args.currentTarget.classList.add('active-item');
    this.ngZone.run(() => this.router.navigateByUrl(path)).then();
  }
}
