<div class="planner-wrapper">
    <ejs-sidebar #sideBar id="plannerSiderBar" [enableGestures]="false" [showBackdrop]="showBackdrop" [closeOnDocumentClick]="closeOnDocumentClick">
      <div class="dock">

        <app-profile id="profileSidebar"></app-profile>
        
        <div *ngIf="this.EventService.localModules.substring(0,1) =='d'" class="sidebar-item dashboard" (click)="onItemClick($event, '/dashboard')">
          <span class="dashboard-image"><span class="icon-dashboard item-image"></span></span>
          <span class="text" title="dashboard">Dashboard</span>
        </div>
        <div *ngIf="this.EventService.localModules.substring(1,2) =='p'" class="sidebar-item products" (click)="onItemClick($event,'/products')">
          <span class="products-image"><span class="icon-products item-image"></span></span>
          <span class="text" title="contact">Products</span>
        </div>
        <div *ngIf="this.EventService.localModules.substring(2,3) =='c'" class="sidebar-item contacts" (click)="onItemClick($event,'/contacts')">
          <span class="contacts-image"><span class="icon-contact item-image"></span></span>
          <span class="text" title="contact">Contacts</span>
        </div>
        <div *ngIf="this.EventService.localModules.substring(3,4) == 's'" class="sidebar-item scheduling" (click)="onItemClick($event,'/scheduling')">
          <span class="scheduler-image"><span class="icon-schedule item-image"></span></span>
          <span class="text" title="scheduling">Calendar</span>
        </div>
        <div *ngIf="this.EventService.localModules.substring(4,5) =='t'" class="sidebar-item tasks" (click)="onItemClick($event,'/tasks')">
          <span class="scheduler-image"><span class="icon-schedule item-image"></span></span>
          <span class="text" title="tasks">Tasks</span>
        </div>
        <div *ngIf="this.EventService.localModules.substring(5,6) =='d'" class="sidebar-item deals" (click)="onItemClick($event,'/deals')">
          <span class="scheduler-image"><span class="icon-schedule item-image"></span></span>
          <span class="text" title="deals">Deals</span>
        </div>
        <div *ngIf="this.EventService.localModules.substring(6,7) =='t'" class="sidebar-item teams" (click)="onItemClick($event,'/teams')">
          <span class="contacts-image"><span class="icon-contact item-image"></span></span>
          <span class="text" title="contact">Team Settings</span>
        </div>
    </div>
    </ejs-sidebar>
    <main>
      <div class="planner-header">
        <div class="side-bar-opener">
          <span class="open-icon e-icons" (click)='btnClick()'></span>
        </div>
        <div class="name-cantainer">
          <div class="image">&nbsp;<img src="/assets/zepys_logo.png" /></div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </main>
  </div>