import { Component, ViewEncapsulation, ViewChild, OnInit, NgZone, ElementRef, AfterViewInit } from '@angular/core';
import { createElement, Internationalization, EmitType, isNullOrUndefined, addClass  } from '@syncfusion/ej2-base';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { DataManager, Query, ReturnOption } from '@syncfusion/ej2-data';
import { ButtonPropsModel, Dialog, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Button } from '@syncfusion/ej2-angular-buttons';
import { SortService, ToolbarService, EditService, PageService, EditSettingsModel, GridComponent, DialogEditEventArgs, DataStateChangeEventArgs, ReorderService, ColumnChooserService, QueryCellInfoEventArgs  } from '@syncfusion/ej2-angular-grids';
import { AddEditLeadComponent } from '../add-edit-lead/add-edit-lead.component';
import { Auth, DataStore, Hub, Predicates } from 'aws-amplify';

import {Contact, EmailQueue, MeetingQueue, QueueStatus, SMSQueue, Task} from '../../models/index';
import { PredicateAll } from '@aws-amplify/datastore/lib-esm/predicates';
import { $dataMetaSchema } from 'ajv';
import { ActionCompleteEventArgs } from '@syncfusion/ej2-inputs';
import { ActionEventArgs } from '@syncfusion/ej2-schedule';

import { EventService} from '../event.service';
import { Deal } from 'src/models';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  providers: [ToolbarService, EditService, PageService, SortService, ReorderService, ColumnChooserService ],
  encapsulation: ViewEncapsulation.None,
})


export class ContactsComponent implements OnInit {
  @ViewChild('grid') gridObj: GridComponent;
  @ViewChild('element') element;
  @ViewChild('popPromptDialogTask') promptDialogTask: DialogComponent;
  @ViewChild('popPromptDialogSMS') promptDialogSMS: DialogComponent;
  @ViewChild('popPromptDialogEmail') promptDialogEmail: DialogComponent;
  @ViewChild('popPromptDialogMeet') promptDialogMeet: DialogComponent;
  @ViewChild('popPromptDialogDeal') promptDialogDeal: DialogComponent;
  @ViewChild('taskDescription') public taskDescription: ElementRef;
  @ViewChild('meetDescription') public meetingDescription: ElementRef;

  public position = { X: 'Right', Y: 'Bottom' };

  public intl: Internationalization = new Internationalization();
  public pageSettings: Object;
  public dataContacts: Object[];
  public toolbar: string[];
  public initialSort: Object;  
  public editSettings: Object;
  public progressrules: Object;
  public numericParams: Object;
  
  public showCloseIcon: Boolean = false;
  public promptHeaderTask: string = 'Assign a task:';
  public promptHeaderSMS: string = 'Send an SMS:';
  public promptHeaderEmail: string = 'Send an Email:';
  public promptHeaderMeet: string = 'Book a meeting:';
  public promptHeaderDeal: string = 'Track a deal:';
  public promptWidth: string = '50%';
  public promptHeight: string = '50%';
  public hidden: Boolean = false;
  public promptActiveContact: Contact;
  public promptActiveType: string;

  public teamName: string;
  public teamID: string;

  public promptDlgBtnClickTask = (): void => {
    this.promptDialogTask.hide();
    this.enqueTask(this.promptActiveContact);
  }

  public promptDlgBtnClickSMS = (): void => {
    this.promptDialogSMS.hide();
    this.enqueSMS(this.promptActiveContact);
  }

  public promptDlgBtnClickEmail = (): void => {
    this.promptDialogEmail.hide();
    this.enqueEmail(this.promptActiveContact);
  }

  public promptDlgBtnClickMeet = (): void => {
    this.promptDialogMeet.hide();
    this.enqueMeeting(this.promptActiveContact);
  }

  public promptDlgBtnClickDeal = (): void => {
    this.promptDialogDeal.hide();
    this.enqueDeal(this.promptActiveContact);
  }
  public onFocus = (): void => {
    this.taskDescription.nativeElement.parentElement.classList.add('e-input-focus');
  }
  
  public animationSettings: Object = { effect: 'None' };
  
  public promptDlgButtonsTask: ButtonPropsModel[] = [{ 
      click: this.promptDlgBtnClickTask.bind(this),
      buttonModel: { content: 'Assign Task', isPrimary: true } }, 
      { click: this.promptDlgBtnClickTask.bind(this), buttonModel: { content: 'Cancel' } }
    ];
  
  public promptDlgButtonsSMS: ButtonPropsModel[] = [{ 
      click: this.promptDlgBtnClickSMS.bind(this),
      buttonModel: { content: 'Send SMS', isPrimary: true } }, 
      { click: this.promptDlgBtnClickSMS.bind(this), buttonModel: { content: 'Cancel' } }
    ];
  
    public promptDlgButtonsEmail: ButtonPropsModel[] = [{ 
      click: this.promptDlgBtnClickEmail.bind(this),
      buttonModel: { content: 'Send Email', isPrimary: true } }, 
      { click: this.promptDlgBtnClickEmail.bind(this), buttonModel: { content: 'Cancel' } }
    ];

    public promptDlgButtonsMeet: ButtonPropsModel[] = [{ 
      click: this.promptDlgBtnClickMeet.bind(this),
      buttonModel: { content: 'Create Meet', isPrimary: true } }, 
      { click: this.promptDlgBtnClickMeet.bind(this), buttonModel: { content: 'Cancel' } }
    ];
  
    public promptDlgButtonsDeal: ButtonPropsModel[] = [{ 
      click: this.promptDlgBtnClickDeal.bind(this),
      buttonModel: { content: 'Create Deal', isPrimary: true } }, 
      { click: this.promptDlgBtnClickDeal.bind(this), buttonModel: { content: 'Cancel' } }
    ];

  public onBlur = (): void => {
    //this.taskDescription.nativeElement.parentElement.classList.remove('e-input-focus');
    //this.onFocus();
}
  constructor(private ngZone: NgZone,  private EventService:EventService, private SettingsService:SettingsService) {
  }

  ngOnInit() {
    //setup the grid:
    this.pageSettings = {  pageSizes: true, pageCount: 5 };
    this.progressrules = { number: true , min: 0};
    this.numericParams = { params: {  format: 'n' } };
    this.initialSort = {
      columns: [{ field: 'first_name', direction: 'Ascending' },
      { field: 'last_name', direction: 'Ascending' }]
    };
    
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    this.toolbar = ['Add', 'Edit', 'Delete', 'Search', 'ColumnChooser'];
      
    Hub.listen('datastore', (data) => {
      if (data.payload.event === 'ready' ) {
        this.gridRefresh();
       }
    })
    
    this.EventService.currentTeamName.subscribe(team => this.teamName = team);
    this.EventService.currentTeamID.subscribe(teamID => this.teamID = teamID);
    this.EventService.createScreenChangeEvent("contact");

    this.gridRefresh();

    //todo fix the busted refresh/reload bug - need to initalise the ngZone...
    this.ngZone.run(() => {;});
  }

  enque(e: any, contact: Contact, type: string){ 
//queue meetings
    switch(type) {
      case 'meet':
        this.promptActiveContact = contact;
        this.promptActiveType = type;
        this.promptDialogMeet.show();
        break;
      case 'email':
        //this.enqueEmail(contact);
        this.promptActiveContact = contact;
        this.promptActiveType = type;
        this.promptDialogEmail.show();
        break;
      case 'sms':
        this.promptActiveContact = contact;
        this.promptActiveType = type;
        this.promptDialogSMS.show();
        break;
      case 'task':
        this.promptActiveContact = contact;
        this.promptActiveType = type;
        this.promptDialogTask.show();
        break;
      case 'deal':
        this.promptActiveContact = contact;
        this.promptActiveType = type;
        this.promptDialogDeal.show();
        break;
      default:
        ; //Do nothing
    }
    
  }

public promptBtnClick = (): void => {
  this.promptDialogTask.show();
}

  queryCellInfo(args: QueryCellInfoEventArgs): void {
    if (args.column.field === 'eng_ranking') {
      if (args.data[args.column.field] < 40) {
          args.cell.querySelector('.statustxt').classList.add('e-lowEng');
          args.cell.querySelector('.statustemp').classList.add('e-lowEng');
      }
    }
  }

  onCreateToast(event: any)
  {
    ;
  }

  toastShow(type: string, ) {
      this.element.show( { title: 'Success!', content: type + ' queued', cssClass: 'e-toast-success'},);
      console.log("Created:" + type);
}

  async CognitoSubID():Promise<string> {
    const user = await Auth.currentAuthenticatedUser({bypassCache: true});
    return(user.attributes.sub);
  }

 async gridRefresh() {
  this.dataContacts = await DataStore.query(Contact, c => c.team_id("eq", this.teamID)); 
  this.gridObj.autoFitColumns();
  this.ngZone.run(() => {this.gridObj.refresh();});
}

OnDataChange(state: ActionEventArgs)
{
  switch(state.requestType) {
    case 'delete':
      this.deleteContactObject(state.data[0].id);
      break;
    case 'save':
      if ((state as any).action == "add") {
        this.addContactObject(state.data);
      } else {
        this.saveContactObject(state.data);
      }
      break;
    case 'beginEdit': break; //Do nothing 
    case 'refresh': break; //Do nothing 
    case 'add': break; //Do nothing 
    case 'sorting': break; //Do nothing 
    case 'paging': break; //Do nothing 
    case 'refresh': break; //Do nothing 
    case 'searching': break; //Do nothing
    case 'reorder': break; //Do nothing
    default:
      console.log("New request Type:");
      console.log(state);
  }
}

async deleteContactObject(id:string) {
  try {
    DataStore.delete(Contact, id);
  } catch (error) {
    console.log("Error deleting contact", error);
  }
}

async enqueMeeting(contact: Contact)
{
  try {
    var cognitoSubID:string = await this.CognitoSubID();
    var teamID:string = await this.teamID;

    await DataStore.save(
      new MeetingQueue ({
        create_id: cognitoSubID,
        team_id: teamID,
        contact: contact,
        description: this.meetingDescription.nativeElement.value,
        status: QueueStatus.CREATED
      })
    );
    this.toastShow('meet');

  } catch (error) {
    console.log("Error scheduling meet", error);
  }

}

async enqueSMS(contact: Contact)
{
  this.EventService.enqueSMS(contact);
  this.toastShow('SMS');
  /*
  try {
    var cognitoSubID:string = await this.CognitoSubID();
    var teamID:string = await this.teamID;

    await DataStore.save(
      new SMSQueue ({
        create_id: cognitoSubID,
        team_id: teamID,
        contact_id: contact.id
      })
    );
    this.toastShow('SMS');
  } catch (error) {
    console.log("Error scheduling SMS", error);
  }*/
}

async enqueEmail(contact: Contact)
{
  try {
    var cognitoSubID:string = await this.CognitoSubID();
    var teamID:string = await this.teamID;

    await DataStore.save(
      new EmailQueue ({
        create_id: cognitoSubID,
        team_id: teamID,
        contact_id: contact.id,
        status: QueueStatus.CREATED
      })
    );
    this.toastShow('email');
  } catch (error) {
    console.log("Error scheduling email", error);
  } 
} 

async enqueTask(contact: Contact)
{
  try {
    var cognitoSubID:string = await this.CognitoSubID();
    var teamID:string = await this.teamID;
    let taskDueDateTime = new Date();
    taskDueDateTime.setDate(taskDueDateTime.getDate() + 1);

    let taskStartDateTime = new Date();
    taskStartDateTime.setDate(taskStartDateTime.getDate());

    await DataStore.save(
      new Task ({
        contact: contact,
        create_id: cognitoSubID,
        description: this.taskDescription.nativeElement.value,
        startDate: taskStartDateTime.toISOString(), //YYYY-MM-DDThh:mm:ss.sssZ
        dueDate: taskDueDateTime.toISOString(), //YYYY-MM-DDThh:mm:ss.sssZ
        team_id: teamID,
        status: "Open"
      })
    );
    this.toastShow('task');
  } catch (error) {
    console.log("Error scheduling task", error);
  }

}

async enqueDeal(contact: Contact)
{
  try {
    var cognitoSubID:string = await this.CognitoSubID();
    var teamID:string = await this.teamID;
    let dealDateTime = new Date();
    dealDateTime.setDate(dealDateTime.getDate() + 1);
    var dealKey:string = await this.SettingsService.DealPhaseGetFirstKey();
console.log(dealKey);
    await DataStore.save(
      new Deal ({
        contact: contact,
        create_id: cognitoSubID,
        description: "New Deal",
        dueDate: dealDateTime.toISOString(), //YYYY-MM-DDThh:mm:ss.sssZ
        team_id: teamID,
        status: dealKey
      })
    );
    this.toastShow('Deal');
  } catch (error) {
    console.log("Error scheduling Deal", error);
  }

}


async addContactObject(contactArray: Record<string, any> | Record<string, any>[])
{
  try {
    var cognitoSubID:string = await this.CognitoSubID();
    var teamID:string = await this.teamID;
    var eng_rating:number = parseInt((contactArray as any).eng_ranking, 10);

    const newContact = await DataStore.save(
      new Contact ({
        first_name: (contactArray as any).first_name,
        last_name: (contactArray as any).last_name,
        email: (contactArray as any).email,
        mobile: (contactArray as any).mobile,
        company: (contactArray as any).company,
        create_id: cognitoSubID,
        team_id: teamID,
        eng_ranking: 0
      })
    );
    this.EventService.createContactEvent(newContact.id);
    
  } catch (error) {
    console.log("Error adding contact", error);
  }
  this.gridRefresh();
}

async saveContactObject(contactArray: Record<string, any> | Record<string, any>[])
{

  try {
    const original = await DataStore.query(Contact, (contactArray as any).id);
    console.log((contactArray as any).eng_ranking);
    var eng_ranking: number = 0;

    if (((contactArray as any).eng_ranking as number) >= 0) {eng_ranking = parseInt((contactArray as any).eng_ranking, 10);}

    await DataStore.save(
      Contact.copyOf(original, updated => {
        updated.first_name=(contactArray as any).first_name,
        updated.last_name=(contactArray as any).last_name,
        updated.email=(contactArray as any).email,
        updated.mobile=(contactArray as any).mobile,
        updated.eng_ranking=eng_ranking,
        updated.company=(contactArray as any).company
      })
    )
  } catch (error) {
    console.log("Error saving contact", error);
  }
  this.gridRefresh();
}
}
