<div class="profile-box">
  <div class="profile-image" (click)='onProfileClick($event)'><img id=profilePic src="/assets/zorro.png" /></div>
    <p>
      <span class="profile-name" (click)='onProfileClick($event)'></span><br>
      <span class="email" (click)='onProfileClick($event)'></span><br>
       
      <span class="team" ></span><br>
      <span class='logout' (click)='signOut($event)'>Logout</span><br><br>
      <amplify-sign-out></amplify-sign-out><br><br>
    </p>
    </div>
    <app-pop-profile #popProfileObj ></app-pop-profile>

