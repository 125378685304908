<div>
<ejs-dialog #editProfileObj width='50%' minHeight='98%' [isModal]='true' [visible]='false' [showCloseIcon]='true' [animationSettings]='animationSettings' target='body'>
    
    <ng-template #content>
        <form #f="ngForm" id='edit-profile-form' [formGroup]="profileForm"><br><br>
                <div>
                    <amplify-s3-image style="--height:80px;--width:80px;" [path]="profilePic" 
                        level="AccessLevel.Public"
                        *ngIf="showPhoto"
                        (click)="editPhoto()">
                    </amplify-s3-image>
                </div>
                <div>
                    <amplify-s3-image-picker 
                    name="ImagePicker"
                    *ngIf="!showPhoto"
                    buttonText="Upload"
                    placeholderHint=""
                    headerHint=""
                    headerTitle="Please choose a profile picture:"
                    level="AccessLevel.Protected"
                    [path]="this.imagePath"
                    track>
                    </amplify-s3-image-picker>
                </div>
                <br>
                <ejs-maskedtextbox placeholder='First Name' id="givenName" floatLabelType="Auto" formControlName="firstName" [class.is-invalid]="firstName.invalid"></ejs-maskedtextbox>
                <label *ngIf="firstName.invalid" [ngClass] = "'error'">Shout it proud, a first name is required.</label>
               <br>
               <br>
               <br>
               <ejs-maskedtextbox placeholder='Last Name' id="lastName" floatLabelType="Auto" formControlName="lastName"></ejs-maskedtextbox><br>
               <label *ngIf="lastName.invalid" [ngClass] = "'error'">Don't be shy, a last name helps your team know who you are, add one in.</label>
               <br>
               <br>
               <ejs-maskedtextbox placeholder='Email' id="email" floatLabelType="Auto" formControlName="email"></ejs-maskedtextbox><br>
               <label *ngIf="email.invalid" [ngClass] = "'error'">Hey we'll need an email to send you awesome updates, be a friend, add in an email address.</label>
               <br>
               <br>
               <!--<ngx-intl-tel-input
                    [cssClass]="'custom'"
                    [preferredCountries]="[CountryISO.Australia]"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="true"
                    [selectedCountryISO]="CountryISO.Australia"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    name="phone"
                    formControlName="phone"
                    (ngModelChange)="onEditPhone($event)"
           ></ngx-intl-tel-input>   -->
               <br>
               <div *ngIf="f.form.controls['phone'].invalid && phone.touched">
                    <label [ngClass] = "'error'">Hey that doesn't look like a phone number?</label>
                </div>
        </form>
    </ng-template>
   <ng-template #footerTemplate>
        <div class="button-container">
          <button ejs-button cssClass="e-normal" (click)='onCancelClick($event)'>Cancel</button>
          <button ejs-button cssClass="e-normal" [isPrimary]="true" (click)='onSaveClick()'>Save</button>
        </div>
    </ng-template>
    <ng-template #header>
        Profile
    </ng-template>
</ejs-dialog>
</div>