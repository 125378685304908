import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { TasksComponent } from './tasks/tasks.component';
import { DealsComponent} from './deals/deals.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ContactsComponent } from './contacts/contacts.component';
import { TeamComponent } from './team/team.component';
import { ProductsComponent } from './products/products.component';
import { ProductManagementComponent } from './product-management/product-management.component';
import { ProductSubscriptionComponent } from './product-subscription/product-subscription.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'calendar', component: CalendarComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'deals', component: DealsComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'productManagement', component: ProductManagementComponent },
  { path: 'productSubscription', component: ProductSubscriptionComponent },
  { path: 'scheduling', component: SchedulingComponent },
  { path: 'tasks', component: TasksComponent },
  { path: 'teams', component: TeamComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
