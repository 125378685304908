<div *ngIf="authState !== 'signedin'; else loggedin" [ngStyle]="{backgroundImage:'url(/assets/bg_zepys.png)',backgroundRepeat: 'no-repeat'}" >
  <amplify-authenticator>
    <amplify-sign-in 
      header-text="Sign into Zepys:"
      slot="sign-in"
      username-alias="email"
    > 
    <div slot="federated-buttons" align="center">
      <input type="image" id="googleLogin" [src]="'/assets/btn_google_signin.png'" (click)="googleLogin($event)"/><br>
      <input type="image" id="facebookLogin" [src]="'/assets/btn_facebook_signin.png'" (click)="facebookLogin($event)"/><br>
      <br>
      <hr>
      <div align="center">or</div>
      <hr><br>
    </div>
    </amplify-sign-in>
    
    <amplify-sign-up
      header-text="Create account with Zepys"
      slot="sign-up"
      username-alias="email"
      [formFields]="formFields"
      slot="sign-up"
    ></amplify-sign-up>
    
    
  </amplify-authenticator>
</div>

<ng-template #loggedin>
  <app-main></app-main> 
  
</ng-template>