import { Component, OnInit, ViewChild } from '@angular/core';
import { EventService} from '../event.service';
import { DataStore } from 'aws-amplify';
import { Product } from '../../models';
import { InPlaceEditor, InPlaceEditorComponent, InPlaceEditorModel } from '@syncfusion/ej2-angular-inplace-editor';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.scss']
})
export class ProductManagementComponent implements OnInit {
  @ViewChild('element') element;
  public position = { X: 'Right', Y: 'Bottom' };

  @ViewChild('prdName') public prdName: InPlaceEditorComponent;
  public prdNameModel: Object = { placeholder: 'Enter the product name' };
  
  @ViewChild('prdTitle') public prdTitle: InPlaceEditorComponent;
  public prdTitleModel: Object = { placeholder: 'Enter the product title' };
  
  @ViewChild('prdSTitle') public prdSTitle: InPlaceEditorComponent;
  public prdSTitleModel: Object = { placeholder: 'Enter the product sub title' };
  
  @ViewChild('prdDesc') public prdDesc: InPlaceEditorComponent;
  public prdDescModel: Object = { placeholder: 'Enter the product description' };
  
  @ViewChild('prdBtnTxt') public prdBtnTxt: InPlaceEditorComponent;
  public prdBtnTxtModel: Object = { placeholder: 'Enter the product button text' };

  @ViewChild('prdBtnURL') public prdBtnURL: InPlaceEditorComponent;
  public prdBtnURLModel: Object = { placeholder: 'Enter the web address for where you want the product button to take sellers to' };
  
  @ViewChild('prdImgURL') public prdImgURL: InPlaceEditorComponent;
  public prdImgURLModel: Object = { placeholder: 'Enter a web address for the product image' };

  constructor(public EventService:EventService) { }

  ngOnInit(): void {
  }

async onCreateProduct()
  {
    var cognitoSubID:string = await this.EventService.localUserID;
    var cognitoTeamID:string = await this.EventService.localTeamID;

    try {
        const NewProduct = await DataStore.save(
          new Product ({
            description: this.prdDesc.value.toString(),
            create_id: cognitoSubID,
            create_team: cognitoTeamID,
            name: this.prdName.value.toString(),
            imageURL: this.prdImgURL.value.toString(),
            btnText: this.prdBtnTxt.value.toString(),
            btnURL:this.prdBtnURL.value.toString(),
            title: this.prdTitle.value.toString(),
            subTitle: this.prdSTitle.value.toString(),
            status: "pending"
          })
          );
          this.element.show( { title: 'Success!', content: "Submitted product for approval", cssClass: 'e-toast-success'},);
        
        } catch (error) {
        console.log("Error adding product", error);
    }
  }

 onCreateToast(event: any)
  {
    ;
  }
}
