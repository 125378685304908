import { Injectable } from '@angular/core';
import { columnSelectionComplete } from '@syncfusion/ej2-angular-grids';
import { DataStore, SortDirection } from 'aws-amplify';
import { Deal, DealKanBanColumnSetting } from 'src/models';
import { EventService} from './event.service';
import { Role } from 'src/models';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(public EventService:EventService) { }
  /******************************
   * Shared setting functions
   */
  public async DealPhaseGetFirstKey() : Promise<string> {
    
    let DealKanabanSettings = await DataStore.query(DealKanBanColumnSetting, 
      setting => setting.team_id("eq",this.EventService.localTeamID),
      {page:0,limit:1, sort: s => s.order(SortDirection.ASCENDING)} ); 

    return DealKanabanSettings[0]["keyField"];
  }


  public async DealPhaseAdd(title:string, key:string, order:number, allowToggle:boolean) {
    try {
      var cognitoSubID:string = await this.EventService.localUserID;
      var teamID:string = await this.EventService.localTeamID;
      
      await DataStore.save(
        new DealKanBanColumnSetting ({
          create_id: cognitoSubID,
          text: title,
          keyField: key, 
          team_id: teamID,
          order: order,
          allowToggle: allowToggle
        })
      );
    } catch (error) {
      console.log("Error adding Deal Phase", error);
    }
  }

  public async DealPhaseRemoveAll() {
    await DataStore.delete(DealKanBanColumnSetting, setting => setting.team_id("eq",this.EventService.localTeamID));
  }


 
  public async DealPhaseAssignAllDeals(ToKey:string) {

    var dealDataAWS = await DataStore.query(Deal, t => t.team_id("eq", this.EventService.localTeamID));
    
    dealDataAWS.forEach(async function (value) {
      try {
        await DataStore.save(
            Deal.copyOf(value, updated => {
              updated.status=ToKey;
          })
        );
      }
      catch (error: any) {
        console.log(error);
      }
    } );
  }
}
