// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const QueueStatus = {
  "CREATED": "created",
  "SCHEDULED": "scheduled"
};

const EventType = {
  "CONTACT": "contact",
  "SM_SQUEUE": "SMSqueue",
  "EMAILQUEUE": "emailqueue",
  "MEETINGQUEUE": "meetingqueue",
  "TASK": "task",
  "TEAM": "team",
  "SCREEN": "screen"
};

const { Contact, Team, TeamMember, Task, Deal, DealKanBanColumnSetting, TaskKanbanColumnSetting, MeetingQueue, SMSQueue, EmailQueue, Role, Advertiser, Product, ReportData, ReportSettings, UserEvent } = initSchema(schema);

export {
  Contact,
  Team,
  TeamMember,
  Task,
  Deal,
  DealKanBanColumnSetting,
  TaskKanbanColumnSetting,
  MeetingQueue,
  SMSQueue,
  EmailQueue,
  Role,
  Advertiser,
  Product,
  ReportData,
  ReportSettings,
  UserEvent,
  QueueStatus,
  EventType
};