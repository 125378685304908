import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';

//AWS:
import { AmplifyUIAngularModule, AmplifyToast } from '@aws-amplify/ui-angular';
import Amplify, { Auth, AuthModeStrategyType } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { Hub } from 'aws-amplify';
import { MainComponent } from './main/main.component';

//Syncfusion:
import { CheckBoxModule, ButtonModule, SwitchModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CalendarModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { InPlaceEditorAllModule } from '@syncfusion/ej2-angular-inplace-editor';
import { KanbanModule } from '@syncfusion/ej2-angular-kanban';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { SidebarModule, TreeViewModule  } from '@syncfusion/ej2-angular-navigations';
import { TextBoxModule, MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';


//Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ContactsComponent } from './contacts/contacts.component';
import { TeamComponent } from './team/team.component';
import { ProfileComponent } from './profile/profile.component';
import { PopProfileComponent } from './pop-profile/pop-profile.component';
import { RouterModule } from '@angular/router';

import { AddEditLeadComponent } from './add-edit-lead/add-edit-lead.component';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { TasksComponent } from './tasks/tasks.component';
import { DealsComponent } from './deals/deals.component';
import { ProductsComponent } from './products/products.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductManagementComponent } from './product-management/product-management.component';
import { ProductSubscriptionComponent } from './product-subscription/product-subscription.component';



//Setup:
// dodgy hack to fix amplify merge account issue: TODO
// Note there is still broken case where you can sign-up with email, not confirm, and then use a social login and the login with fail. TODO
Hub.listen('auth', ({ payload: { event, data } }) => {
  if (event === 'parsingCallbackUrl' && data["url"].includes('MERGED_EXTERNAL_ACCOUNTS')) {
     alert('We\'ve merged your new login into your other Zepys account, please sign-in again'); //Change this to a toaster or reasonably looking msg window TODO
     setTimeout(function() { window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port; }, 500);
   }
  }
 )
 
Amplify.configure({...awsconfig, DataStore: { authModeStrategyType: AuthModeStrategyType.MULTI_AUTH }});

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DashboardComponent,
    CalendarComponent,
    ContactsComponent,
    TeamComponent,
    ProfileComponent,
    PopProfileComponent,
    AddEditLeadComponent,
    SchedulingComponent,
    TasksComponent,
    DealsComponent,
    ProductsComponent,
    ProductManagementComponent,
    ProductSubscriptionComponent
  ],
  imports: [
    AmplifyUIAngularModule,
    AppRoutingModule,
    ButtonModule,
    BrowserModule,
    CalendarModule,
    ChartModule,
    CheckBoxModule,
    DashboardLayoutModule,
    DateTimePickerModule, 
    DialogModule,
    DropDownListModule,
    GridModule,
    InPlaceEditorAllModule,
    KanbanModule,
    MaskedTextBoxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RouterModule,
    ScheduleModule,
    SidebarModule,
    SwitchModule, 
    TextBoxModule,
    ToastModule, 
    TreeViewModule, BrowserAnimationsModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
